











































































import { Turma } from "@/core/models/geral";
import { PageBase } from "@/core/models/shared";
import { TurmaService } from "@/core/services/geral";
import { JSONToCSVConvertor } from '@/assets/scripts/helper';
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class ConfiguracaoCracha extends PageBase {
    
    valid: boolean = true;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

    turma: Turma = new Turma();
    turmas: Turma[] = [];
    turmaService: TurmaService = new TurmaService();
    isTurmaLoading: boolean = false;
    
    overlay: boolean = false;

    lista: any[] = [];
    selectLista: any[] = [];
    loading: boolean = false;
    headers: any[] = [
        { text: "", sortable: false },
        { text: "Nome", value: "nome" },
        { text: "Tipo", value: "tipo"}
    ];

    //SELECT
    todos: boolean = false;
    alunos: boolean = false;
    instrutores: boolean = false;
    treinadores: boolean = false;
    anjos: boolean = false;
    
    filtro: any = {
        turmaId: 0,
        fundo: false,
        select: -1,
        ids: []
    }

    $refs!: {
        form: HTMLFormElement
    }
    
    TODOS(){
        if(this.todos){
            this.alunos = true;
            this.instrutores = true;
            this.treinadores = true;
            this.anjos = true;   
        }
        else{
            this.alunos = false;
            this.instrutores = false;
            this.treinadores = false;
            this.anjos = false;  
        }

        this.Popular();
    }

    @Watch('alunos')
    @Watch('instrutores')
    @Watch('treinadores')
    @Watch('anjos')
    WatchResto(){
        if(this.alunos == false || this.instrutores == false || this.treinadores == false || this.anjos == false){
            this.todos = false;
        }

        if(this.alunos && this.instrutores && this.treinadores && this.anjos){
            this.todos = true;
        }

        this.Popular();
    }

    @Watch('filtro.turmaId')
    WatchTurma(){
        
        if(!this.filtro.turmaId){
            this.lista = [];
            this.filtro.ids = [];
            this.selectLista = [];
            return;
        }

        this.turmaService.ObterPorId(this.filtro.turmaId, "Alunos.Aluno, Instrutores.Usuario, Treinadores.Usuario, Secretarios.Usuario").then(
            res => {
                this.turma = res.data;
            },
            err =>{
                this.$swal('Aviso',err.response.data,'error')
            }
        )
    }

    mounted() {
        this.turmaService.ListarAbertas().then(
            res=> {
                this.turmas = res.data.items
            }
        )   
    }

    Popular(){

        this.lista = [];
        this.selectLista = [];

        if(this.alunos){
            this.turma.alunos.forEach(aluno => {
                this.lista.push({
                    id: aluno.alunoId,
                    nome: aluno.aluno.nome,
                    tipo: "Aluno"
                })
            });
        }

        if(this.instrutores){
            this.turma.instrutores.forEach(instrutor => {
                this.lista.push({
                    id: instrutor.usuarioId,
                    nome: instrutor.usuario.complementar.nome,
                    tipo: "Instrutor"
                })
            });
        }

        if(this.treinadores){
            this.turma.treinadores.forEach(treinador => {
                this.lista.push({
                    id: treinador.usuarioId,
                    nome: treinador.usuario.complementar.nome,
                    tipo: "Treinador"
                })
            });
        }

        if(this.anjos){
            this.turma.secretarios.forEach(secretario => {
                this.lista.push({
                    id: secretario.usuarioId,
                    nome: secretario.usuario.complementar.nome,
                    tipo: "Anjo"
                })
            });
        }
    }

    GerarRelatorio(){
        
        if (this.$refs.form.validate()) {

            if(this.selectLista.length == 0){
                this.$swal('Aviso',"É necessário escolher pelo menos uma pessoa!",'warning');
            }
            else{

                let alunos = "";
                let usuarios = "";

                this.selectLista.forEach((x) => {
                    if(x.tipo == "Aluno")    {
                        if(alunos.length == 0){
                            alunos += x.id
                        }
                        else{
                            alunos += `, ${x.id}`;
                        }
                    }
                    else{
                        if(usuarios.length == 0){
                            usuarios += x.id
                        }
                        else{
                            usuarios += `, ${x.id}`;
                        }
                    }
                })

                let routeLink = this.$router.resolve({name:"impressaoCracha",
                    query:{
                        turmaId: this.filtro.turmaId,
                        fundo: this.filtro.fundo,
                        idAlunos: alunos,
                        idUsuario: usuarios
                    }
                });

                window.open(routeLink.href,'_blank');
            }
        }
    }

    GerarCSV(){

        if (this.$refs.form.validate()) {

            if(this.selectLista.length == 0){
                this.$swal('Aviso',"É necessário escolher pelo menos uma pessoa!",'warning');
                return;
            }

            let alunos = "";
            let usuarios = "";

            this.selectLista.forEach((x) => {
                if(x.tipo == "Aluno")    {
                    if(alunos.length == 0){
                        alunos += x.id
                    }
                    else{
                        alunos += `, ${x.id}`;
                    }
                }
                else{
                    if(usuarios.length == 0){
                        usuarios += x.id
                    }
                    else{
                        usuarios += `, ${x.id}`;
                    }
                }
            });


            this.overlay = true;

            this.turmaService.GerarCracha(this.filtro.turmaId, this.filtro.fundo, alunos, usuarios).then(
                res =>{
                    let item = res.data;

                    JSONToCSVConvertor(res.data.pessoas, "Crachá", true);
                },
                err =>{
                    this.$swal('Aviso',err.response.data,'error')
                }
            ).finally(() => {
                this.overlay = false;
            })


        }

    }

    LimparFiltros(){
        this.filtro.turmaId = 0;
        this.filtro.fundo = false;
        this.filtro.ids = [];
        this.filtro.select = -1;

        this.lista = [];
        this.$refs.form.resetValidation();
    }

}
